import { useCallback } from "react"

import { IEvent } from "src/data/events/types/eventTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"

import { generateEventData } from "./getEventData"

export interface TEventContent {
  icon: React.ReactNode
  title: string
  body: string | false
}

export function useGetTranslatedEvent() {
  const user = useGetUser()
  const clockType = user.clock_type
  const temperatureUnit = user.temperature_unit
  const { _t } = useTranslate()

  const tEvent = useCallback(
    (event: IEvent, timezone?: string): TEventContent => {
      const eventTranslationKeys = generateEventData(event, {
        clockType,
        temperatureUnit,
        timezone,
      })

      return {
        icon: eventTranslationKeys.icon,
        title:
          event.localized_title ||
          _t(
            eventTranslationKeys.titleKey,
            eventTranslationKeys.sortedTitleParams
          ),
        body:
          event.localized_body ||
          (!!eventTranslationKeys.bodyKey &&
            _t<string>(
              eventTranslationKeys.bodyKey,
              eventTranslationKeys.sortedBodyParams
            )),
      }
    },
    [_t, clockType, temperatureUnit]
  )

  return tEvent
}
